<template>
    <div class="fixed z-[300] w-full h-full backdrop-blur-md"></div>
    <FormBlock class="match-event-form">
        <div class="match-event-form__title">Додати гол</div>
        <div class="flex gap-[20px] justify-center">
            <div class="match-event-form__time">
                <div class="input-component__label">основний час</div>
                <div class="input-component__field time-field">
                    <input 
                        type="number"
                        class="text-center w-full"
                        :value="newGoal.mainTime"
                        @change="e => {newGoal.mainTime = e.target.value; newGoal.time = e.target.value}"
                    />
                </div>
            </div>
            <div class="match-event-form__time">
                <div class="input-component__label">додатковий час</div>
                <div class="input-component__field time-field">
                    <input 
                        type="number"
                        class="text-center w-full"
                        :value="newGoal.additionalTime"
                        @change="e => {newGoal.additionalTime = e.target.value; newGoal.time = +newGoal.mainTime + +e.target.value}"
                    />
                </div>
            </div>
        </div>

        <div class="match-event-form__event-type">
            <div>нам забили</div>
            <div class="switch"><IphoneSwitch :value="whoReleasedGoal" @input="e => whoReleasedGoal = e" /></div>
            <div>ми забили</div>
            <div v-if="whoReleasedGoal">пенальті</div>
            <div v-if="whoReleasedGoal" class="switch"><IphoneSwitch :value="newGoal.data.isPenalty" @input="e => newGoal.data.isPenalty = e" /></div>
            <div v-if="!whoReleasedGoal">автогол</div>
            <div v-if="!whoReleasedGoal" class="switch"><IphoneSwitch :value="newGoal.data.isAutoGoal" @input="e => newGoal.data.isAutoGoal = e" /></div>
        </div>

        <div v-if="whoReleasedGoal" class="match-event-form__our-goal-block">

            <div>
                <SelectComponent 
                    :label="'гол забив'" 
                    :options="teamNames" 
                    :name="'гол забив'" 

                    :default="newGoal.data.player.name"
                    @change="e => newGoal.data.player.name = e"
                />
            </div>
            <div class="h-[35px] mt-[20px]">
                <SelectComponent 
                    
                    :label="'асист'" 
                    :options="teamNames" 
                    :name="'асист'" 

                    :default="newGoal.data.assistant.name"
                    @change="e => newGoal.data.assistant.name = e"
                />
            </div>

            <div class="match-event-form__details">
                <img src="@/assets/icons/ball_icon.svg" class="w-[60px]">
                <div>{{ newGoal.time }}’’ ГОЛ! - {{ newGoal.data.player.name }}</div>
                <div>асист. - {{ newGoal.data.assistant.name }}</div>
            </div>

        </div>
        <div v-else class="match-event-form__our-goal-block">
            <div v-if="newGoal.data.isAutoGoal">
                <SelectComponent 
                    :label="'автогол реалізував'" 
                    :options="teamNames" 
                    :name="'автогол реалізував'" 

                    :default="newGoal.data.player.name"
                    @change="e => newGoal.data.player.name = e"
                />
            </div>

            <div class="flex w-full justify-center" :class="{
                'h-[180px]' : newGoal.data.isAutoGoal,
                'h-[221px]' : !newGoal.data.isAutoGoal
            }">
                <img src="@/assets/icons/red_ball_icon.svg" class="w-[60px]">
            </div>
            <div>
                <SelectComponent 
                    :label="'гол пропустив'" 
                    :options="goalkeepersNames" 
                    :name="'гол пропустив'" 

                    :default="newGoal.data.goalkeeper.name"
                    @change="e => newGoal.data.goalkeeper.name = e"
                />
            </div>
        </div>

        <div class="match-event-form__buttons">
            <ButtonComponent @click="save">
                Зберегти
            </ButtonComponent>
            <ButtonComponent @click="$emit('close')">
                Відмінити
            </ButtonComponent>
        </div>

    </FormBlock>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
    name: 'GoalForm',

    props: {
        team: {
            type: Array,
            required: true
        }
    },

    setup(props, { emit }) {
        const whoReleasedGoal = ref(true);
        const isPenalty = ref(false);

        const teamNames = ref(props.team.map(player => player.name))
        const goalkeepersNames = ref(props.team.filter(player => player.position === 'ВР').map(player => player.name))

        const newGoal = ref(
            {
                id: new Date().getTime(),
                type: 'GoalEvent',
                time: 0,
                mainTime: 0,
                additionalTime: 0,
                data: {
                    isPenalty: false,
                    isAutoGoal: false,
                    player: {
                        name: 'Не вибрано',
                    },
                    assistant: {
                        name: 'Не вибрано',
                    },
                    goalkeeper: {
                        name: 'Не вибрано',
                    }
                }
            }
        )

        const save = () => {
            if (whoReleasedGoal.value) {
                emit('save', newGoal.value)
            } else {
                newGoal.value.type = 'OpponentGoal';
                
                if (newGoal.value.data.isAutoGoal) {
                    newGoal.value.data.assistant = null;
                } else {
                    // newGoal.value.data = null;
                }

                emit('save', newGoal.value)
            }
        }

        return {
            whoReleasedGoal,
            isPenalty,
            newGoal,
            teamNames,
            goalkeepersNames,
            save
        }
    }
}
</script>

<style>
    
</style>