<template>
    <DeleteForm 
        v-if="deleteFormFlag" 
        @no="deleteFormFlag = false" 
        @yes="deleteFormFlag = false; deleteMatch(matchForDeletion)"    
    />
    <div class="matches-list">
        <NewMatchForm 
            v-if="newMatchFlag" 
            @close="newMatchFlag = false"
            @updateMatches="updateMatches"    
        />
        <div class="matches-list__menu">
            <div class="matches-list__search-bar">
                <InputComponent v-if="!newMatchFlag" placeholder="Пошук..." />
            </div>
            <div class="w-full ml-[30px] flex items-center ">
                <div class="text-[#8C8C8C] w-[250px] text-start font-medium relative text-[14px]">
                    Відображення елементів
                    <span v-if="elementsViewTable" class="text-[#8FC408]">таблиця</span>
                    <span v-else class="text-[#FA1367]">групи</span>
                </div>
                <div class="ml-[20px] relative bottom-[4px]">
                    <IphoneSwitch :isOnValue="elementsViewTable" @change="elementsViewTable = !elementsViewTable"/>
                </div>
            </div>
            <div class="matches-list__add-block">
                <ButtonComponent @click="newMatchFlag = true">
                    Додати матч
                </ButtonComponent>
            </div>
        </div>
        <TableWrapper v-if="elementsViewTable">
            <TableComponent :fullWidth="true">
                <TableHeader>
                <TableHeaderRow>
                    <TableHeaderCell :value="'дата'" />
                    <TableHeaderCell :value="'матч'" />
                    <TableHeaderCell :value="'ліга'" />
                    <TableHeaderCell :value="'сезон'" />
                    <TableHeaderCell :value="'тур'" />
                    <TableHeaderCell :value="'рахунок'" />
                    <TableHeaderCell :value="'тривалість'" />
                    <TableHeaderCell :value="'схема'" />
                    <TableHeaderCell :value="'дистанція за матч'" />
                    <TableHeaderCell :value="'швидкісний метраж'" />
                    <TableHeaderCell :value="'оцінка за матч'" />
                    <TableHeaderCell :value="''" />
                </TableHeaderRow>
                </TableHeader>
                <TableBody>
                    <TableRow  
                        v-if= "allMatches.length != 0"
                        v-for="match in allMatches" 
                        :class="{
                            'matches-list__report-completed' : !compareDateToNow(match.date),
                            'matches-list__report-pending' : compareDateToNow(match.date)
                            }"    
                    >
                        <TableCell :asChild="true">
                            <DateComponent :day="match.date.split('T')[0].split('-').reverse().join('.')" :time="match.time" />
                        </TableCell>
                        <TableCell :value="`${match.firstTeamName} - ${match.secondTeamName}`" />
                        <TableCell :value="match.league" />
                        <TableCell :value="match.season" />
                        <TableCell :value="match.tour" />
                        <TableCell :value="`${match.firstTeamScore}:${match.secondTeamScore}`" />
                        <TableCell :value="+match.matchTime ? match.matchTime : '-'" />
                        <TableCell :value="match.scheme ? match.scheme : '-'" />
                        <TableCell :value="match.summary_points_list ? formatNumber(match.summary_points_list[0]) : '-'" />
                        <TableCell :value="match.summary_points_list ? formatNumber(match.summary_points_list[3]) : '-'" />
                        <TableCell :value="match.delegate ? match.delegate : '-'" />
                        <TableCell :asChild="true">
                            <div class="w-full flex gap-[10px] justify-end items-center"> 
                                <div v-if="match.startTeam && !(match.events?.length > 1)">
                                    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 17.6458V7.05426C1 4.20025 1 2.77325 1.87868 1.88663C2.75736 1 4.17157 1 7 1H11C13.8284 1 15.2426 1 16.1213 1.88663C17 2.77325 17 4.20025 17 7.05426V17.6458C17 19.1575 17 19.9133 16.538 20.2108C15.7831 20.6971 14.6161 19.6774 14.0291 19.3073C13.5441 19.0014 13.3017 18.8485 13.0325 18.8397C12.7417 18.8301 12.4949 18.9768 11.9709 19.3073L10.06 20.5124C9.54454 20.8374 9.28679 21 9 21C8.71321 21 8.45546 20.8374 7.94 20.5124L6.02913 19.3073C5.54415 19.0014 5.30166 18.8485 5.03253 18.8397C4.74172 18.8301 4.49493 18.9768 3.97087 19.3073C3.38395 19.6774 2.21687 20.6971 1.46195 20.2108C1 19.9133 1 19.1575 1 17.6458Z" stroke="#C59014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8 10H5" stroke="#C59014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M11 6L5 6" stroke="#C59014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <div @click="$router.push('/match-player-marks/' + match.match_report_id)" v-if="match.events?.length > 1" class="cursor-pointer">
                                    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.544 7.04498C20.848 7.4713 21 7.68447 21 8C21 8.31553 20.848 8.52869 20.544 8.95501C19.1779 10.8706 15.6892 15 11 15C6.31078 15 2.8221 10.8706 1.45604 8.95502C1.15201 8.5287 1 8.31553 1 8C1 7.68447 1.15201 7.47131 1.45604 7.04499C2.8221 5.12944 6.31078 1 11 1C15.6892 1 19.1779 5.12944 20.544 7.04498Z" stroke="#339989" stroke-width="1.5"/>
                                        <path d="M14.0005 8C14.0005 6.34315 12.6573 5 11.0005 5C9.34363 5 8.00049 6.34315 8.00049 8C8.00049 9.65685 9.34363 11 11.0005 11C12.6573 11 14.0005 9.65685 14.0005 8Z" stroke="#339989" stroke-width="1.5"/>
                                    </svg>
                                </div>
                                <button 
                                    class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                    @click="$router.push('/match-center/report/' + match.match_report_id)"
                                >
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 20H21" stroke="#F8FF82" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12 20H21" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12 20H21" stroke="#ABD3EC" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#F8FF82" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#ABD3EC" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>
                                <button 
                                    @click="deleteFormFlag = true; matchForDeletion = match.match_report_id"
                                    class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                >
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 6H5H21" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M10 11V17" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M14 11V17" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                </button>
                            </div>
                        </TableCell>
                    </TableRow>
                    <TableRow v-else>
                        <TableCell :value="'Немає матчів'" :colspan="12" />
                    </TableRow>
                    
                </TableBody>
            </TableComponent>
        </TableWrapper>

        <div v-else>

            <div v-if="!selectedLeague">
                <div v-for="league in allLeagues" @click="selectedLeague = league.label" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span><span class="text-black">{{ league.label }}</span></span>
                </div>
            </div>
            <div v-else-if="!selectedSeason">
                <div @click="selectedLeague = null" class="bg-[#BD9135] mb-[20px] flex justify-center items-center w-[228px] h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span>Назад</span>
                </div>
                <div v-for="season in currentLeagueSeason(selectedLeague)" @click="selectedSeason = season" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span><span class="text-black">{{ season }}</span></span>
                </div>
            </div>
            <div v-else>
                <div @click="selectedSeason = null" class="bg-[#BD9135] mb-[20px] flex justify-center items-center w-[228px] h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span>Назад</span>
                </div>
                <TableWrapper>
                    <TableComponent :fullWidth="true">
                        <TableHeader>
                        <TableHeaderRow>
                            <TableHeaderCell :value="'дата'" />
                            <TableHeaderCell :value="'матч'" />
                            <TableHeaderCell :value="'ліга'" />
                            <TableHeaderCell :value="'сезон'" />
                            <TableHeaderCell :value="'тур'" />
                            <TableHeaderCell :value="'рахунок'" />
                            <TableHeaderCell :value="'тривалість'" />
                            <TableHeaderCell :value="'схема'" />
                            <TableHeaderCell :value="'асистент 1'" />
                            <TableHeaderCell :value="'асистент 2'" />
                            <TableHeaderCell :value="'делегат'" />
                            <TableHeaderCell :value="''" />
                        </TableHeaderRow>
                        </TableHeader>
                        <TableBody>
                            <TableRow  
                                v-if= "sortedMatches.length != 0"
                                v-for="match in sortedMatches" 
                                :class="{
                                    'matches-list__report-completed' : !compareDateToNow(match.date),
                                    'matches-list__report-pending' : compareDateToNow(match.date)
                                    }"    
                            >
                                <TableCell :asChild="true">
                                    <DateComponent :day="match.date.split('T')[0].split('-').reverse().join('.')" :time="match.time" />
                                </TableCell>
                                <TableCell :value="`${match.firstTeamName} - ${match.secondTeamName}`" />
                                <TableCell :value="match.league" />
                                <TableCell :value="match.season" />
                                <TableCell :value="match.tour" />
                                <TableCell :value="`${match.firstTeamScore}:${match.secondTeamScore}`" />
                                <TableCell :value="+match.matchTime ? match.matchTime : '-'" />
                                <TableCell :value="match.scheme ? match.scheme : '-'" />
                                <TableCell :value="match.summary_points_list ? formatNumber(match.summary_points_list[0]) : '-'" />
                                <TableCell :value="match.summary_points_list ? formatNumber(match.summary_points_list[3]) : '-'" />
                                <TableCell :value="match.delegate ? match.delegate : '-'" />
                                <TableCell :asChild="true">
                                    <div class="w-full flex gap-[10px] justify-end items-center"> 
                                        <div v-if="match.startTeam && !(match.events?.length > 1)">
                                            <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 17.6458V7.05426C1 4.20025 1 2.77325 1.87868 1.88663C2.75736 1 4.17157 1 7 1H11C13.8284 1 15.2426 1 16.1213 1.88663C17 2.77325 17 4.20025 17 7.05426V17.6458C17 19.1575 17 19.9133 16.538 20.2108C15.7831 20.6971 14.6161 19.6774 14.0291 19.3073C13.5441 19.0014 13.3017 18.8485 13.0325 18.8397C12.7417 18.8301 12.4949 18.9768 11.9709 19.3073L10.06 20.5124C9.54454 20.8374 9.28679 21 9 21C8.71321 21 8.45546 20.8374 7.94 20.5124L6.02913 19.3073C5.54415 19.0014 5.30166 18.8485 5.03253 18.8397C4.74172 18.8301 4.49493 18.9768 3.97087 19.3073C3.38395 19.6774 2.21687 20.6971 1.46195 20.2108C1 19.9133 1 19.1575 1 17.6458Z" stroke="#C59014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M8 10H5" stroke="#C59014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M11 6L5 6" stroke="#C59014" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        <div @click="$router.push('/match-player-marks/' + match.match_report_id)" v-if="match.events?.length > 1" class="cursor-pointer">
                                            <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M20.544 7.04498C20.848 7.4713 21 7.68447 21 8C21 8.31553 20.848 8.52869 20.544 8.95501C19.1779 10.8706 15.6892 15 11 15C6.31078 15 2.8221 10.8706 1.45604 8.95502C1.15201 8.5287 1 8.31553 1 8C1 7.68447 1.15201 7.47131 1.45604 7.04499C2.8221 5.12944 6.31078 1 11 1C15.6892 1 19.1779 5.12944 20.544 7.04498Z" stroke="#339989" stroke-width="1.5"/>
                                                <path d="M14.0005 8C14.0005 6.34315 12.6573 5 11.0005 5C9.34363 5 8.00049 6.34315 8.00049 8C8.00049 9.65685 9.34363 11 11.0005 11C12.6573 11 14.0005 9.65685 14.0005 8Z" stroke="#339989" stroke-width="1.5"/>
                                            </svg>
                                        </div>
                                        <button 
                                            class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                            @click="$router.push('/match-center/report/' + match.match_report_id)"
                                        >
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 20H21" stroke="#F8FF82" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M12 20H21" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M12 20H21" stroke="#ABD3EC" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#F8FF82" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M16.5 3.50023C16.8978 3.1024 17.4374 2.87891 18 2.87891C18.2786 2.87891 18.5544 2.93378 18.8118 3.04038C19.0692 3.14699 19.303 3.30324 19.5 3.50023C19.697 3.69721 19.8532 3.93106 19.9598 4.18843C20.0665 4.4458 20.1213 4.72165 20.1213 5.00023C20.1213 5.2788 20.0665 5.55465 19.9598 5.81202C19.8532 6.06939 19.697 6.30324 19.5 6.50023L7 19.0002L3 20.0002L4 16.0002L16.5 3.50023Z" stroke="#ABD3EC" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </button>
                                        <button 
                                            @click="deleteFormFlag = true; matchForDeletion = match.match_report_id"
                                            class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                        >
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3 6H5H21" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M10 11V17" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M14 11V17" stroke="#DE3A73" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>

                                        </button>
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow v-else>
                                <TableCell :value="'Немає матчів'" :colspan="12" />
                            </TableRow>
                            
                        </TableBody>
                    </TableComponent>
                </TableWrapper>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue'
import { getAllMatchReports, deleteMatchReport, getAllLeagues, getAllTeams, getGpsReportByMatchId } from '@/services/apiRequests'
import NewMatchForm from './components/NewMatchForm.vue'
import DeleteForm from './components/DeleteForm.vue'
import IphoneSwitch from './components/IphoneSwitch.vue'

export default {
    name: 'Matches',

    setup() {
        const newMatchFlag = ref(false)
        const allMatches = ref([])

        const matchForDeletion = ref(null)
        const deleteFormFlag = ref(false)

        const elementsViewTable = ref(true)

        const selectedLeague = ref(null)
        const selectedSeason = ref(null)

        const allLeagues = ref([])
        const allOtherTeams = ref([])
        const allSeasons = ref([])

        const sortedMatches = ref([])

        const updateFlag = ref(false)

        const formatNumber = (number) => {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }

        watch([selectedLeague, selectedSeason], async () => {
            if (selectedLeague && selectedSeason) {
                sortedMatches.value = allMatches.value.filter(match => {
                    return match.league === selectedLeague.value && match.season === selectedSeason.value
                })
            }
        })

        const compareDateToNow = (date) => {
            const now = new Date()
            const matchDate = new Date(date)
            return matchDate > now
        }

        onMounted(async () => {
            await updateMatches()
            allLeagues.value = (await getAllLeagues()).map(league => {
                return {
                    id: league.league_id,
                    label: league.league_name
                }
            });
            allOtherTeams.value = (await getAllTeams()).map(team => {
                return {
                    id: team.team_id,
                    label: team.team_name,
                    league: team.league_id,
                    logo: team.team_logo,
                    season: team.season
                }
            });

            allSeasons.value = allOtherTeams.value.map(team => {
                return team.season
            });

            allSeasons.value = [...new Set(allSeasons.value)];
            console.log(allLeagues.value)
        })

        const currentLeagueSeason = (league_name) => {
            const currentLeague = allLeagues.value.find(league => {
                return league.label === league_name
            })
            console.log('currentLeague', currentLeague)
            return [...new Set(allOtherTeams.value.filter(team => {
                return team.league === currentLeague.id
            }).map(team => {
                return team.season
            }))]
        }

        const updateMatches = async () => {
            updateFlag.value = false
            allMatches.value = await getAllMatchReports()

            for (let i = 0; i < allMatches.value.length; i++) {
                const match = allMatches.value[i]
                const matchGpsReport = (await getGpsReportByMatchId(match.match_report_id)).gps_report
                if (matchGpsReport) {
                    allMatches.value[i].average_points_list = matchGpsReport.average_points_list
                    allMatches.value[i].summary_points_list = matchGpsReport.summary_points_list
                    allMatches.value[i].min_points_list = matchGpsReport.min_points_list
                    allMatches.value[i].max_points_list = matchGpsReport.max_points_list
                }
            }
            console.log('allMatches.value ------ ', allMatches.value)
            updateFlag.value = true
            // allMatches.value = allMatches.value.sort((a, b) => {
            //     return new Date(a.date) - new Date(b.date)
            // })
        }

        const deleteMatch = async (id) => {
            await deleteMatchReport(id)
            await updateMatches()
        }

        return {
            newMatchFlag,
            allMatches,
            matchForDeletion,
            deleteFormFlag,
            compareDateToNow,
            deleteMatch,
            updateMatches,
            elementsViewTable,
            selectedLeague,
            selectedSeason,
            allLeagues,
            allOtherTeams,
            allSeasons,
            sortedMatches,
            currentLeagueSeason,
            updateFlag,
            formatNumber
        }
    },

    components: {
        NewMatchForm,
        DeleteForm,
        IphoneSwitch
    }
}
</script>

<style>
    
</style>