import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from '../layouts/MainLayout.vue'

const routes = [
  {
    path: localStorage.getItem('userType') == 'scout' ? '/admin_dash' : '/',
    name: 'dashboard',
    component: MainLayout,
    meta: { component: 'Dashboard' }
  },
  {
    path: '/trainers',
    name: 'trainers',
    component: MainLayout,
    meta: { component: 'Trainers' }
  },
  {
    path: '/groups',
    name: 'groups',
    component: MainLayout,
    meta: { component: 'Groups' }
  },
  {
    path: '/students',
    name: 'students',
    component: MainLayout,
    meta: { component: 'Students' }
  },
  { 
    path: '/students/:id', 
    name: 'current-student',
    component: MainLayout,
    meta: { component: 'Students' }
  },
  {
    path: '/transaction-log',
    name: 'transaction-log',
    component: MainLayout,
    meta: { component: 'TransactionLog' }
  },
  {
    path: '/locations',
    name: 'locations',
    component: MainLayout,
    meta: { component: 'Locations' }
  },
  {
    path: localStorage.getItem('userType') == 'scout' ? '/' : '/calendar',
    name: 'calendar',
    component: MainLayout,
    meta: { component: 'Calendar' }
  },
  {
    path: '/incomes',
    name: 'incomes',
    component: MainLayout,
    meta: { component: 'Incomes' }
  },
  {
    path: '/incomes/tx_id/:tx_id',
    name: 'incomesWithCurrentTxId',
    component: MainLayout,
    meta: { 
      component: 'Incomes' 
    }
  },
  {
    path: '/incomes/balance_id/:balance_id',
    name: 'incomesWithCurrentBalanceId',
    component: MainLayout,
    meta: { 
      component: 'Incomes' 
    }
  },
  {
    path: '/outlays',
    name: 'outlays',
    component: MainLayout,
    meta: { component: 'Outlays' }
  },
  {
    path: '/outlays/tx_id/:tx_id',
    name: 'outlaysWithCurrentTxId',
    component: MainLayout,
    meta: { 
      component: 'Outlays' 
    }
  },
  {
    path: '/outlays/balance_id/:balance_id',
    name: 'outlaysWithCurrentBalanceId',
    component: MainLayout,
    meta: { 
      component: 'Outlays' 
    }
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: MainLayout,
    meta: { component: 'Transactions' }
  },
  {
    path: '/transactions/tx_id/:tx_id',
    name: 'transactionsWithCurrentTxId',
    component: MainLayout,
    meta: { 
      component: 'Transactions' 
    }
  },
  {
    path: '/transactions/balance_id/:balance_id',
    name: 'transactionsWithCurrentBalanceId',
    component: MainLayout,
    meta: { 
      component: 'Transactions' 
    }
  },
  {
    path: '/admins',
    name: 'adminsView',
    component: MainLayout,
    meta: { 
      component: 'Admins' 
    }
  },
  {
    path: '/outlays-categories',
    name: 'OutlaysCategories',
    component: MainLayout,
    meta: { 
      component: 'OutlaysCategories' 
    }
  },
  {
    path: '/club-outlays',
    name: 'ClubOutlays',
    component: MainLayout,
    meta: { 
      component: 'ClubOutlays' 
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: MainLayout,
    meta: { 
      component: 'Settings' 
    }
  },
  {
    path: '/age-groups',
    name: 'AgeGroups',
    component: MainLayout,
    meta: { 
      component: 'AgeGroups' 
    }
  },
  {
    path: '/training-constructor',
    name: 'TrainingConstructor',
    component: MainLayout,
    meta: { 
      component: 'TrainingConstructor' 
    }
  },
  {
    path: '/training-programs',
    name: 'TrainingPrograms',
    component: MainLayout,
    meta: { 
      component: 'TrainingPrograms' 
    }
  },
  {
    path: '/training-schemes',
    name: 'TrainingSchemes',
    component: MainLayout,
    meta: { 
      component: 'TrainingSchemes' 
    }
  },
  {
    path: '/training-schemes/:element_id',
    name: 'AddingSchemaToElement',
    component: MainLayout,
    meta: { 
      component: 'TrainingSchemes' 
    }
  },
  {
    path: '/training-elements',
    name: 'TrainingElements',
    component: MainLayout,
    meta: { 
      component: 'TrainingElements' 
    }
  },
  {
    path: '/trainings-constructor',
    name: 'TrainingsConstructor',
    component: MainLayout,
    meta: { 
      component: 'Trainings' 
    }
  },
  {
    path: '/training-program',
    name: 'TrainingProgramView',
    component: MainLayout,
    meta: { 
      component: 'TrainingProgramView' 
    }
  },
  {
    path: '/new-training-element',
    name: 'ElementForm',
    component: MainLayout,
    meta: { 
      component: 'ElementForm' 
    }
  },
  {
    path: '/training-creation-form',
    name: 'TrainingCreationForm',
    component: MainLayout,
    meta: { 
      component: 'TrainingCreationForm' 
    }
  },  
  {
    path: '/training-element/:id',
    name: 'ElementUpdateForm',
    component: MainLayout,
    meta: { 
      component: 'ElementUpdateForm' 
    }
  },


  {
    path: '/training-summary/:id',
    name: 'TrainingUpdateForm',
    component: MainLayout,
    meta: { 
      component: 'TrainingUpdateForm' 
    }
  },

  {
    path: '/create-training-program',
    name: 'TrainingProgramCreationForm',
    component: MainLayout,
    meta: { 
      component: 'TrainingProgramCreationForm' 
    }
  },
  {
    path: '/training-summary-addons',
    name: 'TrainingSummaryAddons',
    component: MainLayout,
    meta: { 
      component: 'TrainingSummaryAddons' 
    }
  },

  {
    path: '/training-program/:id',
    name: 'TrainingProgramUpdateForm',
    component: MainLayout,
    meta: { 
      component: 'TrainingProgramUpdateForm' 
    }
  },

  {
    path: '/match-center/matches',
    name: 'Matches',
    component: MainLayout,
    meta: { 
      component: 'Matches' 
    }
  },

  {
    path: '/match-center/report/:id',
    name: 'MatchReportForm',
    component: MainLayout,
    meta: { 
      component: 'MatchReportForm' 
    }
  },

  {
    path: '/match-center/teams',
    name: 'Teams',
    component: MainLayout,
    meta: { 
      component: 'Teams' 
    }
  },
  {
    path: '/standard-constructor',
    name: 'StandardConstructor',
    component: MainLayout,
    meta: { 
      component: 'StandardConstructor' 
    }
  },
  {
    path: '/player-mark/:student_id/:match_id',
    name: 'PlayerMatchMarkView',
    component: MainLayout,
    meta: { 
      component: 'PlayerMatchMarkView' 
    }
  },
  {
    path: '/match-player-marks/:match_id',
    name: 'PlayerMatchReportForm',
    component: MainLayout,
    meta: { 
      component: 'PlayerMatchReportForm' 
    }
  },
  {
    path: '/player-scout-report/:student_id',
    name: 'PlayerScoutReportForm',
    component: MainLayout,
    meta: { 
      component: 'PlayerScoutReportForm' 
    }
  },
  {
    path: '/player-scout-report-view/:report_id',
    name: 'PlayerScoutReportView',
    component: MainLayout,
    meta: { 
      component: 'PlayerScoutReportView' 
    }
  },
  {
    path: '/scouting-dashboard',
    name: 'ScoutDashboard',
    component: MainLayout,
    meta: { 
      component: 'ScoutDashboard' 
    }
  },
  {
    path: '/scout-profiles',
    name: 'Scouts',
    component: MainLayout,
    meta: { 
      component: 'Scouts' 
    }
  },
  {
    path: '/scout-reports',
    name: 'ScoutReports',
    component: MainLayout,
    meta: { 
      component: 'ScoutReports' 
    }
  },
  {
    path: '/scout-players',
    name: 'ScoutPlayers',
    component: MainLayout,
    meta: { 
      component: 'ScoutPlayers' 
    }
  },
  {
    path: '/shadow-teams',
    name: 'ShadowTeams',
    component: MainLayout,
    meta: { 
      component: 'ShadowTeams' 
    }
  },
  {
    path: '/select-student-to-form-scout-report',
    name: 'SelectStudentToFormScoutReport',
    component: MainLayout,
    meta: { 
      component: 'StudentSelectionForm' 
    }
  },
  {
    path: '/view-alien-player/:player_id',
    name: 'AlienPlayerFullCard',
    component: MainLayout,
    meta: { 
      component: 'AlienPlayerFullCard' 
    }
  },
  {
    path: '/alien-player-scout-report/:player_id',
    name: 'AlienPlayerScoutReportForm',
    component: MainLayout,
    meta: { 
      component: 'AlienPlayerScoutReportForm' 
    }
  },
  {
    path: '/alien-player-scout-report-view/:report_id',
    name: 'AlienPlayerScoutReportView',
    component: MainLayout,
    meta: { 
      component: 'AlienPlayerScoutReportView' 
    }
  },
  {
    path: '/select-student-to-form-shadow-team',
    name: 'ShadowTeamPlayerSelection',
    component: MainLayout,
    meta: { 
      component: 'ShadowTeamPlayerSelection' 
    }
  },
  {
    path: '/create-shadow-team',
    name: 'CreateShadowTeam',
    component: MainLayout,
    meta: { 
      component: 'CreateShadowTeam' 
    }
  },
  {
    path: '/shadow-team-view/:shadow_team_id',
    name: 'ShadowTeamView',
    component: MainLayout,
    meta: { 
      component: 'ShadowTeamView' 
    }
  },
  {
    path: '/current-select-student-to-form-shadow-team',
    name: 'CurrentShadowTeamPlayerSelection',
    component: MainLayout,
    meta: { 
      component: 'CurrentShadowTeamPlayerSelection' 
    }
  },
  {
    path: '/scout-players-comparison',
    name: 'ScoutPlayersComparison',
    component: MainLayout,
    meta: { 
      component: 'ScoutPlayersComparison' 
    }
  },
  {
    path: '/scout-players-compare-view',
    name: 'ComparePlayersView',
    component: MainLayout,
    meta: { 
      component: 'ComparePlayersView' 
    }
  },
  {
    path: '/best-shadow-team',
    name: 'BestShadowTeam',
    component: MainLayout,
    meta: { 
      component: 'BestShadowTeam' 
    }
  },

  {
    path: '/add-other-player-to-match/:match_id',
    name: 'AddOtherPlayerToMatch',
    component: MainLayout,
    meta: { 
      component: 'AddOtherPlayerSelection' 
    }
  },

  {
    path: '/add-gps-report-for-match/:match_id',
    name: 'AddGpsReportForMatch',
    component: MainLayout,
    meta: { 
      component: 'AddGpsReportForMatch' 
    }
  },

  {
    path: '/player-summary-report/:student_id',
    name: 'PlayerSummaryScoutReportForm',
    component: MainLayout,
    meta: { 
      component: 'PlayerSummaryScoutReportForm' 
    }
  },
  {
    path: '/player-summary-report-view/:report_id',
    name: 'PlayerScoutSummaryReportView',
    component: MainLayout,
    meta: { 
      component: 'PlayerScoutSummaryReportView' 
    }
  },

  {
    path: '/alien_player_summary_report/:student_id',
    name: 'AlienPlayerSummaryScoutReportForm',
    component: MainLayout,
    meta: { 
      component: 'AlienPlayerSummaryScoutReportForm' 
    }
  },
  {
    path: '/alien_player_summary_report-view/:report_id',
    name: 'AlienPlayerScoutSummaryReportView',
    component: MainLayout,
    meta: { 
      component: 'AlienPlayerScoutSummaryReportView' 
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
